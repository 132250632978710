<template>
    <b-nav-item 
        :disabled="numberCumsToAsk === 0"
        :to="{name: 'deliveries'}"
    >
        <div class="position-relative">
            <b-icon icon="cart-fill" scale="1.7"/>

            <div 
                v-if="numberCumsToAsk > 0"
                class="
                    position-absolute bottom-3 left-3 z-2 
                    text-white bg-secondary 
                    rounded text-sm bold
                " 
                style="padding: 1px 3px"
            >
                {{numberCumsToAsk}}
            </div>
        </div>
    </b-nav-item>
</template>

<script>
    import { mapState } from '@/store/deliveries';

    export default{
        computed: {
            ...mapState(['cumStock']),

            numberCumsToAsk(){
                return Object.values(this.cumStock).reduce((acc, cum) => acc + cum.amount, 0);
            }
        },

    }
</script>
