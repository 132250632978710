<template>
    <div>
        <b-navbar :type="null" class="d-flex justify-content-between position-fixed top-0 left-0 right-0 bg-white shadow z-10">
            <!-- Logo a la izquierda -->
            <b-navbar-brand to="/">
                <img :src="logo" alt="Logo mi medicamento" height="30"/>
                <h2 class="d-none d-sm-inline-block mb-0 ml-1">Mi medicamento</h2>
            </b-navbar-brand>
            
            
            <b-navbar-nav class="d-flex align-items-center gap-4">
                <!--<b-nav-text>
                    <span class="font-weight-bold">{{ navContent.title }} </span> 
                    <router-link :to="{name: navContent.to}" class="btn btn-primary btn-sm">
                        Clic acá
                    </router-link>
                </b-nav-text>
                -->
                <CartCTA />
            </b-navbar-nav>
            
        </b-navbar>

        <b-container style="padding-top: 64px;" class="pb-1">
            <router-view />
        </b-container>
    </div>
</template>

<script>
import logo from '@images/logo/isologo.png';
import CartCTA from './partials/CartCTA.vue';

export default{
    components: {
        CartCTA,
    },
    data() {
        return {
            logo,
        }
    },
    computed: {
        navContent() {
            const name = this.$route.name;

            switch(name){
                case 'guest':
                    return {
                        title: 'Si eres farmacia',
                        to: 'guest_pharmacy',
                    }
                case 'guest_pharmacy':
                    return {
                        title: 'Buscas un medicamento?',
                        to: 'guest',
                    }
                default:
                    return {
                        title: 'Para regresar',
                        to: 'guest',
                    }
            }
        }
    }
}
</script>
